/*jshint esversion: 6 */
import global from './global.js';


export default function t(input){
  var self=this;

  var i18n=dictionary[global.language];

  var key='';
  try{
    key=input.toLowerCase().trim();
  }
  catch(e){
    key=input;
  }


  if(!i18n){
    return input;
  }
  else if (i18n=={}){
    return input;
  }
  else{
    if(i18n[key]){
      return i18n[key];
    }
    else{
      if(global.language!=='en'){
        console.log('||'+key+'|| not found');
      }
      return input;
    }
  }
}

var dictionary={
  "it": {
    "ciao":"Ciao",
    "accedi":"Accedi",
    "show_password":"Mostra password",
    "welcome_tec": "Benvenuto tecnico",
    "farm": "Azienda",
    "action": "Azione",
    "welcome": "Benvenuto",
    "access_anagrafica":"Accedi all'anagrafica",
    "anagrafe":"Anagrafe",
    "su":"su",
    "mesi":"mesi",
    "parti":"parti",
    "search_in_table":"Cerca in tabella..",
    "advanced_registry":"Anagrafica avanzata",
    "chose_language": "Scegli la tua lingua",
    "synchronize_data": "Sincronizza i dati",
    "back_home_page": "Torna alla Home Page",
    "impostazioni": "Impostazioni",
    "versione":"Versione",
    "rams_choice":"Scelta degli arieti",
    "ram_message":"Da questa funzione è possibile vedere la compatibilità del proprio gregge con gli arieti degli altri utenti e contattarne i proprietari.",
    "go_to_choice":"Vai alla scelta",
    "back":"Indietro",
  },
  "en": {
    "ciao":"Hi",
    "accedi":"Log in",
    "show_password":"Show password",
    "welcome_tec": "Welcome technician",
    "farm": "Farm",
    "action": "Action",
    "welcome": "Welcome",
    "access_anagrafica":"Access the registry",
    "anagrafe":"Registry",
    "su":"over",
    "mesi":"months",
    "parti":"deliveries",
    "search_in_table":"Search in table..",
    "advanced_registry":"Advanced registry",
    "chose_language": "Choose your language",
    "synchronize_data": "Synchronize data",
    "back_home_page": "Back to Home Page",
    "impostazioni": "Settings",
    "versione":"Version",
    "rams_choice":"Choice of rams",
    "ram_message":"From this function is possible to see the compatibility of your flock with the rams of other users and contact its owners.",
    "go_to_choice":"Go to the choice",
    "back":"Back",
    "esci":"Log out"

  }
};
