/*jshint esversion: 6 */

import jstorage from 'jstorage';
import global from "./global.js";
import * as tools_routes from "./tools.js";

// Qui c'è il setting dell'app salvato nel jStorage.(?)
class storage {

  // Serve ad inizializzare e creare l'oggetto vuoto mantenendo solo i campi salvati dal reset, altrimenti crea lui. (es: deviceID)
  init(init_settings) {
    var self=this;

    var settings={
      // deviceID:getGuid(),
      version:'1.0.0',
      // timestamp:new Date(),
      release_date:'02/12/2019',
      res:{}
    };

    if(typeof init_settings!=='undefined'){
      settings=jQuery.extend({},settings, init_settings);
    }
    // debugger


    var deviceInfo=self.getDeviceID();
    // Per prendere tutte le info device + notification
    // console.log("Debug 1", settings);
    settings = self.get();
    settings=jQuery.extend({},settings, deviceInfo);


    var oldVal={};
    if (self.get()!=null) {
      oldVal=self.get();
      // console.log(oldVal);
    }

    // Per ogni valore già presente su jStorage, lo salva. Inizializza solo i campi non ancora presenti.
    jQuery.each(oldVal,function(k,v){
      settings[k]=v;
    });

    // console.log("Debug 2", settings);
    self.save(settings);
    // console.log(settings, this.get());
    return this.get();
  }

  // Serve a prendere i dati salvati su jStorage
  get(key){
    if(global.no_persistence) {
      return this.settings_saved;
    }
    else{
      var newSet=jQuery.jStorage.get(global.settingsName);
      return newSet;
    }
  }

  // Serve a settare un valore nuovo su jStorage, prima del save fare il get()
  save(thisIsTheValue){
    // alert('sss');
    // console.log(val);
    if(global.no_persistence) {
      this.settings_saved=thisIsTheValue;
      return this.settings_saved;
    }
    else{
      var settings=jQuery.jStorage.set(global.settingsName, thisIsTheValue);
      return settings;
    }
  }

  // Serve a resettare jStorage, possiamo salvare dei singoli valori (es: id_device)
  reset(){
    var oldVal=this.get();
    var newVal={};
    if (typeof oldVal.id_device!=='undefined') {
      newVal.id_device=oldVal.id_device;
    }

    this.save(newVal);
  }

  // Elimina del tutto l'oggetto di jStorage
  deleteKey(){
    if(!global.no_persistence){
      jQuery.jStorage.deleteKey(global.settingsName);
    }
  }

  // Inserisce nello storage i dati del device
  getDeviceID(){
    var self = this;
    var settings = {};
    console.log("faccio la chiamata per le notifiche");
    // TODO 1: id_device va creato/assegnato solo se non esiste.
    // prendere lo storage, verificare se presnete uuid ...
    // TODO 2: lancia API di registrazione dei device nella tabella [app_device]
    if (global.is_cordova()) {
      if( device ) {
        if( typeof settings.id_device == 'undefined' ){
          settings.id_device = device.uuid;
        }
        settings.platform  = device.platform;
        settings.model = device.model;
        settings.version_os = device.version;
      }
    }
    else {
      if( typeof settings.id_device == 'undefined' ){
        settings.id_device = getGuid();
      }
      settings.platform  = 'Desktop';
      if( navigator ){
        settings.model = navigator.platform;
        settings.version_os = navigator.userAgent;
      }
      else {
        settings.model = 'Web';
        settings.version_os = '';
      }
    }


    if( global.notification ){
      console.log("GLN",global.notification.getToken);
      var s=self.get();
      if(s && (typeof s.notification_token == 'undefined' || s.notification_token==null) ){
        // se le notifiche sono attive e il token non esiste
        console.log("Prende il token");
        global.notification.getToken(function(token){
          console.log('michele',token, settings);
          settings.notification_token = token;

          var storage = self.get();
          settings=jQuery.extend({},storage, settings);
          self.save(settings);
          // console.log("Debug deviceid", settings);

          tools_routes.register();
          return settings;
        }, function(error){
          // TODO : come gestire utente con notifica disabilitata
          alert(error);
          return settings;
        });
      }
      else {
        // TODO : verificare se il token scade
        return settings;
      }
      return settings;

    }
    else {
      return settings;
    }

    console.log(settings);
    return settings;
  }
}



function getGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4()+s4()+'-'+s4()+'-'+s4()+'-'+s4()+'-'+s4()+s4()+s4();
}

export default new storage();
