/*jshint esversion: 6 */

import * as partecipa_storage from './partecipa_storage.js';
import global from "../../global.js";
import gps from '../../core/gps.js';
import t from '../../translate.js';
import dataManagement from "./data.js";

//Nuova funzione finalizza (ripulita)
export function finalizza(id_farm, id_survey_schema, guid){




  //stop the GPS
  gps.stopGPS();

  //load the relevant data
  //var all_surveys=partecipa_storage.selectSurveysStorage(id_farm, id_survey_schema);

  //get the current survey
  var survey=partecipa_storage.selectSurvey(id_farm, id_survey_schema, guid);

  //get the best coordinates (if available)
  var best_coordinates=null;
  if(survey.coordinates){
    best_coordinates=survey.coordinates;
  }
  else{
    if(global.coordinates){
      // Non lo usiamo
      //Todo check if global coord is too_old
      best_coordinates=global.coordinates;
    }
  }

  //get the crop from the survey and the connected fields
  var crop=partecipa_storage.getSurveyCrop(id_survey_schema);

  //get the fields sorted by distance (if best_coordinates is null get fields without distance)
  var available_fields=partecipa_storage.selectFieldsWithDistance(id_farm, crop.id_crop, best_coordinates);


  //INIZIO DEBUG
  var debug=false;
  var debug_type='GPS_FIELDS_ALLDISTANCE';


  if(debug){
    if(debug_type=='NO'){
      delete survey.coordinates;
      best_coordinates=null;
      available_fields=[];
    }
    else if(debug_type=="NOGPS_FIELD_OK"){
      delete survey.coordinates;
      best_coordinates=null;
      available_fields=[
        {"distance":9999998,"flag": "COORD","field":{"guid":"71f2f8ef-f1df-0127-4fcd-4f7fdc4e6912","sync":true,"lat":"42","lon":"12","name":"Campo 9","id_crop":2,"provider":"Manual"}}
      ];
    }
    else if(debug_type=="NOGPS_FIELD_NO"){
      delete survey.coordinates;
      best_coordinates=null;
      available_fields=[
        {"distance":9999998,"flag":"NO_COORD","field":{"guid":"71f2f8ef-f1df-0127-4fcd-4f7fdc4e6912","sync":true,"lat":"0","lon":"0","name":"Campo 9","id_crop":2,"provider":"Manual"}}
      ];
    }
    else if(debug_type=="NOGPS_FIELDS"){
      delete survey.coordinates;
      best_coordinates=null;
      available_fields=[
        {"distance":9999999,"flag":"NO_COORD","field":{"guid":"xxxx-12-12","sync":true,"lat":"0","lon":"0","name":"Senza coord","id_crop":2,"provider":"Manual"}},
        {"distance":9999998,"flag": "COORD","field":{"guid":"71f2f8ef-f1df-0127-4fcd-4f7fdc4e6912","sync":true,"lat":"42","lon":"12","name":"Campo 9","id_crop":2,"provider":"Manual"}}
      ];
    }
    else if(debug_type=='GPS_FIELD_NO'){
      available_fields=[];
      best_coordinates={"provider":"html5","bufferedLatitude":43.68262709801716,"bufferedLongitude":10.453116198017161,"bufferedAccuracy":791.4957907151685,"timestamp":1622303319794};

    }
    else if(debug_type=='GPS_FIELDS'){
      available_fields=[
        {"distance":9999998,"flag":"NO_COORD","field":{"guid":"xxxx-12-12","sync":true,"lat":"0","lon":"0","name":"Senza coord","id_crop":2,"provider":"Manual"}},
        {"distance":12.5,"flag": "COORD","field":{"guid":"71f2f8ef-f1df-0127-4fcd-4f7fdc4e6912","sync":true,"lat":"42","lon":"12","name":"Campo 9","id_crop":2,"provider":"Manual"}}
      ];
      best_coordinates={"provider":"html5","bufferedLatitude":43.68262709801716,"bufferedLongitude":10.453116198017161,"bufferedAccuracy":791.4957907151685,"timestamp":1622303319794};

    }
    else if(debug_type=='GPS_FIELDS_ALLDISTANCE'){
      available_fields=[
        {"distance":1,"flag":"NO_COORD","field":{"guid":"xxxx-12-12","sync":true,"lat":"0","lon":"0","name":"Campo2","id_crop":2,"provider":"Manual"}},
        {"distance":2200,"flag": "COORD","field":{"guid":"71f2f8ef-f1df-0127-4fcd-4f7fdc4e6912","sync":true,"lat":"42","lon":"12","name":"Campo 9","id_crop":2,"provider":"Manual"}}
      ];
      best_coordinates={"provider":"html5","bufferedLatitude":43.68262709801716,"bufferedLongitude":10.453116198017161,"bufferedAccuracy":791.4957907151685,"timestamp":1622303319794};
    }
  }


  //Sort the fields
  available_fields.sort(sortByDistance);


  var new_field_name="Campo";
  if (survey.coordinates && survey.coordinates.place) {
    new_field_name=survey.coordinates.place;
  }
  var prog=partecipa_storage.getProgressiveNumber();

  new_field_name+=" "+crop.crop_name+" "+prog;

  var body="";

  var debug2=false;
  if(debug2){
    body+='<pre>';
    body+="<h5>Survey:<h5>"+JSON.stringify(survey.coordinates, null, 4);
    // body+="<h5>Global</h5>"+JSON.stringify(global.coordinates);
    body+="<h5>Fields:</h5>";
    jQuery.each(available_fields, function(kf,vf){
      body+='<li>'+JSON.stringify(vf, null, 4)+'</li>';
    });
    body+='</pre>';
    // body+="</p>";
  }


  var title='<h3 class="text-center" style="margin-top:0;">'+t("Scegli il campo")+'.</h3>';

  //inserisci il body
  body+=finalizza_get_body();
  //calcola lo score (se è nel survey schema)
  body+=calculateScore(id_survey_schema, survey);

  body+="<div id='feedback' style='display:none;' class='block block-strong f7-info text-center'></div>";

  //Render the page
  var page={
    title: title,
    content: body,
    exclude_card:true
  };
  global.theme.render(page);


  if(!best_coordinates){
    jQuery("#finalizza_rungps_message").html("Attenzione non è stata presa la posizione con il GPS").parent().show();
    jQuery('#GPS').show();
  }

  //Add the map to the page
  finalizzaAddMap(best_coordinates,available_fields);

  if (best_coordinates!=null) {
    if (id_survey_schema==='99') {
      app_feedback(survey, best_coordinates);
    }
  }

  //Activate the manual coordinate system (save the data in hidden field)
  jQuery("#manual-Map-checkbox input").change(function() {
    jQuery('#manual-Map-checkbox').css('opacity','0.5');
    jQuery(this).attr("disabled", true);
    gps.manualMap(
      function(coord){
        jQuery('#survey_gps_latitude').val(coord.lat);
        jQuery('#survey_gps_longitude').val(coord.lon);
        jQuery('#survey_gps_provider').val("Manual");
      }
    );
  });

  //Create the two options: newField (create a new field) of oldField (choose an existing field)
  jQuery(".field_segment").unbind().click(function() {
    var lastFieldType=jQuery('.button-active').attr('id');

    jQuery('.field_segment').removeClass('button-active');
    jQuery(this).addClass('button-active');

    var fieldType=jQuery(this).attr('id');
    if (fieldType!=lastFieldType) {
      var html='';



      if (fieldType=='newField') {
        html+=finalizzaCreateNewFieldForm(new_field_name);

        if (survey.coordinates) {
          var survey_coords=[survey.coordinates.bufferedLatitude,survey.coordinates.bufferedLongitude];

          if (global.marker) {
            global.map.removeLayer(global.marker);
          }
          global.marker = L.marker(survey_coords).addTo(global.map);
          global.map.setView(survey_coords, 16);
        }
      }
      else {
        html+=finalizzaSelectField(available_fields, survey, best_coordinates);
      }

      jQuery('#segment_content').html(html);

      //Update the map after choosing a field (ma non è valido se il campo non ha le coordinate)
      if(jQuery("#campo_select").length>0){
        jQuery('#campo_select').change(function(){

          var guid_old_field=jQuery('#campo_select').val();
          if (guid_old_field=='') {
            guid_old_field=jQuery('#campo_select').attr('value');
          }
          jQuery.each(available_fields,function(j,d){
            if (d.field.guid==guid_old_field) {

              //Code executed after selecting a field

              var new_coords=null;
              if(best_coordinates){
                new_coords=[best_coordinates.bufferedLatitude, best_coordinates.bufferedLongitude];
              }

              if(d.flag!='NO_COORD'){
                new_coords=[d.field.lat,d.field.lon];

                if(d.distance>1.5 && best_coordinates){
                  jQuery("#select_field_comment").html("Attenzione! stai scegliendo un campo lontano "+d.distance.toFixed(1)+"km");
                }
              }
              else{
                if(best_coordinates){
                  //Better tot show the message
                  // jQuery("#finalizza_rungps_message").html("Stai cambiano le coordinate del campo");
                }
                else{
                  jQuery("#finalizza_rungps_message").html("Attenzione stai creando un nuovo campo senza coordinate clicca su \"prendi coordinate GPS\" per associare il campo con delle cordinate").parent().show();
                  jQuery('#GPS').show();
                }
              }

              if (global.marker) {
                global.map.removeLayer(global.marker);
              }

              if(new_coords){
                if (global.marker) {
                  global.map.removeLayer(global.marker);
                }
                global.marker = L.marker(new_coords).addTo(global.map);
                global.map.setView(new_coords, 16);
              }
            }
          });

        }).change();

      }
    }
  });

  if(best_coordinates==null){
    //1. senza coordinate e senza campi
    if(available_fields.length==0){
      jQuery('#newField').click();
      jQuery("#oldField").hide();
      jQuery('#fieldSelector').hide();

      jQuery("#finalizza_rungps_message").html("Attenzione stai creando un nuovo campo senza coordinate clicca su \"prendi coordinate GPS\" per associare il campo con delle cordinate").parent().show();
    }
    //1.2 senza coordinate ma con almeno 1 campo
    else {
      jQuery("#oldField").click();
    }
  }
  //la coordinata c'è
  else{
    if(available_fields.length==0){
      jQuery('#newField').click();
      jQuery("#oldField").hide();
      jQuery('#fieldSelector').hide();
    }
    else{



      //get the best field
      var best_field={};
      var best_field_position=getBestFieldPosition(available_fields,survey,best_coordinates);
      try{
        best_field=available_fields[best_field_position];
      }
      catch(e){
        console.log(e);
      }

      //if the user is editing a survey already with the field keep the field selector
      if(survey.field){
        jQuery("#oldField").click();
      }
      //If the field is  close or without coordinate accept it
      else if(best_field.distance<1.5 || best_field.flag=='NO_COORD'){
        //survey close or without coordinate can be accepted
        jQuery("#oldField").click();
      }
      else{
        jQuery("#newField").click();
      }


    }
  }

  //get again the coordinates
  jQuery("#runGPS").click(function() {

    global.app.dialog.preloader("Sto prendendo le coordinate..");

    gps.startGPS({
      buffer_max_size: 1,
      position_temp: function(pos){
        console.log("GPS3_temp",pos);
        // updateGPSFinalizza(pos);
        global.app.dialog.close();


        jQuery('#survey_gps_latitude').val(pos.bufferedLatitude);
        jQuery('#survey_gps_longitude').val(pos.bufferedLongitude);
        jQuery('#survey_gps_provider').val(pos.provider);


        if (global.marker) {
          global.map.removeLayer(global.marker);
        }
        var new_coords=[pos.bufferedLatitude,pos.bufferedLongitude];
        global.marker = L.marker(new_coords).addTo(global.map);
        global.map.setView(new_coords, 16);

      },
      position: function(pos){
        console.log("GPS3_final",pos);
        global.app.dialog.close();


        jQuery('#survey_gps_latitude').val(pos.bufferedLatitude);
        jQuery('#survey_gps_longitude').val(pos.bufferedLongitude);
        jQuery('#survey_gps_provider').val(pos.provider);


        if (global.marker) {
          global.map.removeLayer(global.marker);
        }
        var new_coords=[pos.bufferedLatitude,pos.bufferedLongitude];
        global.marker = L.marker(new_coords).addTo(global.map);
        global.map.setView(new_coords, 16);

      },
      error: function(error){
        console.log("GPS not available");
      }
    });
  });

  var button='';
  button+='<button id="salva_survey" class="button button-large">Salva</button>';
  jQuery('#footer').html(button);

  jQuery("#salva_survey").click(function() {
    survey.coordinates=best_coordinates;
    finalizzaSaveSurvey(id_farm, survey, available_fields, id_survey_schema, crop.id_crop);
  });

}


function sortByDistance( a, b ) {
  if ( a.distance < b.distance ){
    return -1;
  }
  if ( a.distance > b.distance ){
    return 1;
  }
  return 0;
}

//transform the survey and save it
function finalizzaSaveSurvey(id_farm, survey, available_fields, id_survey_schema, id_crop){

  var dm=new dataManagement();



  //Add the manual or recalculated coordinates
  if(jQuery("#survey_gps_latitude").length>0){
    if(jQuery("#survey_gps_latitude").val()!==''){
      if(survey.coordinates){
        survey.coordinates.bufferedLatitudeGPS=survey.coordinates.bufferedLatitude;
        survey.coordinates.bufferedLongitudeGPS=survey.coordinates.bufferedLongitude;
        survey.coordinates.bufferedAccuracyGPS=survey.coordinates.bufferedAccuracy;
      }
      else{
        survey.coordinates={};
      }
      survey.coordinates.bufferedAccuracy=0;
      survey.coordinates.bufferedLatitude=jQuery('#survey_gps_latitude').val();
      survey.coordinates.bufferedLongitude=jQuery('#survey_gps_longitude').val();
      survey.coordinates.provider=jQuery('#survey_gps_provider').val();
      survey.coordinates.timestamp=new Date().getTime();
    }
  }

  //get what has been choosed by the user
  var fieldType=jQuery('.button-active').attr('id');
  if (fieldType=='oldField') {

    //Use the field chosed by the user
    var fieldGuid=jQuery('#campo_select').val();
    if (fieldGuid=='') {
      fieldGuid=jQuery('#campo_select').attr('value');
    }
    survey.field=fieldGuid;



    //change the field coordinates
    if (survey.coordinates && survey.coordinates.bufferedLatitude) {
      jQuery.each(available_fields,function(k,v){
        if (v.field.guid==fieldGuid) {
          //if the coordinates are manually chaged or the choosen field has no coordinates update the field
          if(survey.coordinates.provider=='Manual' || v.flag=='NO_COORD'){
            var new_field=v.field;
            new_field.lat=survey.coordinates.bufferedLatitude;
            new_field.lon=survey.coordinates.bufferedLongitude;
            if(new_field.lat!=0 && new_field.lon!=0){
              // debugger
              partecipa_storage.updateField(id_farm, new_field.guid, new_field);
            }
          }
        }
      });
    }


  }
  //create  a new field
  else if (fieldType=='newField') {

    var request_insert_field={
      "name": jQuery('#placename').val(),
      "coordinates": survey.coordinates,
      "id_crop": id_crop

    };
    //the function just generate a valid field object (with guid)
    var new_field=dm.insertNewCampo(request_insert_field);

    partecipa_storage.insertFieldStorage(id_farm, id_survey_schema, new_field);

    survey.field=new_field.guid;


  }
  survey.closed=true;

  console.log("New:",survey);
  partecipa_storage.updatePartialSurvey(id_farm, id_survey_schema, survey.guid, survey);

  if (global.nomeApp=='Agro Abruzzo') {
    location.hash="";
    location.hash="partecipa";
  }
  else {
    location.hash="";
  }

  // Chiamare sync
  setTimeout(function(){
    dm.getSurveySchema(id_farm)
    .then( (arg) => {

      global.app.notification.create({
        icon: '<i class="f7-icons">hand_thumbs_up_fill</i>',
        title: global.nomeApp,
        titleRightText: '',
        text: 'Il rilievo è stato salvato sul telefono.',
        closeOnClick: true,
        closeTimeout: 3000,
      }).open();
    })
    .catch( (err) => {
      alert("Errore durante il salvataggio sul dispositivo: "+err);
    });
  },1000);

}


//OK: create the form containing the name of the nuew field
function finalizzaCreateNewFieldForm(new_field_name){
  var html='';
  html+='<form class="list" id="form">';
    html+='<ul>';
      html+='<li>';
        html+='<div class="item-content item-input">';
          html+='<div class="item-inner">';
          html+='<div class="item-title item-label">'+t("Nome del Campo")+'</div>';
            html+='<div class="item-input-wrap">';
              html+='<input type="text" id="placename" value="'+new_field_name+'">';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';
    html+='</ul>';
  html+='</form>';
  return html;
}


//OK print the select of the available fields (already sorted)
//The available fields is a composed object the field guid is in available_fields]0].field.guid
function finalizzaSelectField(available_fields, survey, coordinates){

  var html="";

  //select di campi già esistentis...
  html+="<div style='margin-bottom:20px;'>";
  html+='<div class="list">';
  html+='<ul>';
  html+='<li>';
  //data-toolbar-close-text="Chiudi"
  html+='<a class="item-link smart-select smart-select-init"  data-close-on-select="true"  data-open-in="sheet">';

  var selected_label='';


  if (available_fields.length>0) {


    var best_field_position=getBestFieldPosition(available_fields,survey,coordinates );

    html+='<select id="campo_select">';
    console.log(available_fields);
    jQuery.each(available_fields,function(k,v){
      var selected='';


      var field_name=v.field.name+" ";
      if(v.flag=='DIST'){
        if(v.distance<1.5){
          field_name+="("+(v.distance*1000).toFixed(0)+" m)";
        }
        else{
          field_name+="("+v.distance.toFixed(1)+" km)";
        }
      }
      else if(v.flag=='NO_COORD'){
        field_name+="(senza coordinate)";
      }


      if (k==best_field_position) {
        selected='selected';
        selected_label=field_name;
      }



      html+='<option value="'+v.field.guid+'" '+selected+'>'+field_name+'</option>';
    });
    html+='</select>';
  }
  else{
    html+="Attenzione!!! Non ci sono campi da scegliere";
  }

  html+='<div class="item-content">';
  html+='<div class="item-inner">';
  html+='<div class="item-title">Scegli il campo</div>';
  html+='<div class="item-after">'+selected_label+'</div>';
  html+='</div>';
  html+='</div>';
  html+='</a>';
  html+='</li>';
  html+='</ul>';
  html+='</div>';
  html+='</div>';
  html+="<div id='select_field_comment'></div>";

  return html;
}


//The function return
function getBestFieldPosition(available_fields,survey,coordinates){
  var best_field_position=0;
  var stop_search=false;

  //Iteration to finde best field position
  jQuery.each(available_fields,function(k,v){
    if (survey.field===v.field.guid) {
      best_field_position=k;
    }
    else{
      if(coordinates && ! stop_search){
        if(v.distance<1.5 || v.flag=='NO_COORD'){
          best_field_position=k;
          stop_search=true;
        }
        //vince il primo se distance<1500
        //altrimenti vince il primo di NO_COORD
        //se non ne trova nessuno vince il primo,
        //ma in fase di salvataggiuo sggerisce la creazione diu un nuovo campo
      }
    }
  });
  return best_field_position;
}


//OK add the map to the finalizza with a marker on cooordinates and green points for surveys
//TODO servono i surveys===
function finalizzaAddMap(coordinates, available_fields){
  global.map = L.map('minimap');

  var defaultCenter=null;

  if(coordinates){
    if(coordinates.bufferedLatitude){
      defaultCenter=[coordinates.bufferedLatitude, coordinates.bufferedLongitude];
    }
    else{
      if(coordinates[0]){
        defaultCenter=coordinates;
      }
    }
  }

  var googleStreets = L.tileLayer('//{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
    maxZoom: 20,
    subdomains:['mt0','mt1','mt2','mt3']
  });

  var googleSat = L.tileLayer('//{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
    maxZoom: 20,
    subdomains:['mt0','mt1','mt2','mt3']
  });

  var googleHybrid = L.tileLayer('//{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',{
    maxZoom: 20,
    subdomains:['mt0','mt1','mt2','mt3']
  }).addTo(global.map);

  var baseMaps = {
    "Base": googleStreets,
    "Ibrida": googleHybrid,
    "Satellite": googleSat,
  };

  L.control.layers(baseMaps,  {}).addTo(global.map);

  if(defaultCenter){
    global.map.setView(defaultCenter, 16);
    global.marker = L.marker(defaultCenter).addTo(global.map);
  }
  //i there is not center use some standard positioning
  else{
    //Italian (default)
    var default_coordinates=[43,12];
    var default_zoom=6;
    //Projects  can ovverride
    if(global.default_coordinates){
      default_coordinates=global.default_coordinates;
    }
    if(global.default_zoom){
      default_zoom=global.default_zoom;
    }

    global.map.setView(default_coordinates,default_zoom);
  }

  if(available_fields){
    jQuery.each(available_fields, function(k,v){
      if (v.field.lat) {
        L.circle([v.field.lat, v.field.lon],
          { color: 'green', fillColor: '#0f0', fillOpacity: 0.5, radius: 5 }
        ).addTo(global.map);
      }
    });
  }
}


//OK Get the htmcode of finalizza function
function finalizza_get_body(){

  var body='';
  body+='<p id="fieldSelector" class="segmented segmented-strong">';
  body+='<button id="newField" class="field_segment button">Crea nuovo campo</button>';
  body+='<button id="oldField" class="field_segment button">Usa campo esistente</button>';
  body+='<span class="segmented-highlight"></span>';
  body+='</p>';
  body+='<div id="segment_content"></div>';

  body+='<div id="finalizza_rungps_div" style="display:none;" class="f7-warning block block-strong"><div id="finalizza_rungps_message"></div></div>';
  body+='<div id="GPS" class="block block-strong" style="display:none;" ><button style="display: flex;justify-content: center;align-items: center;" id="runGPS" class="col button button-large button-fill button-round"><i style="width: 24px;margin-right: 15px;color: white;" class="f7-icons">location_fill</i>Prendi coordinate GPS</button></div>';

  body+="<input type='hidden' id='survey_gps_latitude' />";
  body+="<input type='hidden' id='survey_gps_longitude' />";
  body+="<input type='hidden' id='survey_gps_provider' />";


  body+='<div class="list simple-list" style="margin-bottom: -15px;">';
  body+='<ul>';
  body+='<li>';
  body+='<span>Coordinate manuali</span>';
  body+='<label id="manual-Map-checkbox" class="toggle toggle-init color-green">';
  body+='<input id="manual-Map" type="checkbox">';
  body+='<span class="toggle-icon"></span>';
  body+='</label>';
  body+='</li>';
  body+='</ul>';
  body+='</div>';

  body+="<div id='minimap'></div>";
  body+="<div id='campo_vicino' class='text-center block'></div>";

  return body;
}


export function calculateScore(id_survey_schema, survey){

  var body='';
  var survey_schema=partecipa_storage.selectSurveySchema(id_survey_schema);
  var schema=JSON.parse(survey_schema.schema);

  if (typeof schema=='string') {
    schema=JSON.parse(schema);
  }

  if (schema.scores) {
    var score=0;

    jQuery.each(survey.weights,function(k,v){
      score+=parseFloat(v);
    });

    body+="<h2 class='text-center'>"+t("Hai totalizzato "+score+" punti su 10")+".</h2>";

    body+='<div class="pyro_cont">';
    body+='<div class="pyro">';
    body+='<div class="before"></div>';
    body+='<div class="after"></div>';
    body+='</div>';
    body+='</div>';

  }
  return body;

}



// -------------- FUNZIONE PER FEEDBACK MOSCA (SEGGIANO) ----------------------
function app_feedback(res, best_coordinates) {
  console.log(res);

  var lat=best_coordinates.bufferedLatitude;
  var lon=best_coordinates.bufferedLongitude;

  var urladdr = global.base_path+'mod_aedita_services/'+"get_tiles?custom_class=it.aedit.proj.arsia.ae5Module&gisAjax=true&agroamb_app=ok&lat="+lat+"&lon="+lon+"&date="+res.timestamp.substr(0,10)+"&layer=mosca&feedback_olivo=true";
  jQuery.ajax({
    type: 'POST',
    url: urladdr,
    dataType: "jsonp",
    success: function(tiles){
      console.log(tiles);
      var fdb = "";
      var sum_totale=0;
      var sum_attiva=0;
      var sopra_soglia=0;
      var exist_one=false;
      fdb+="<div style='display: none'>";
      jQuery.each(tiles.records, function(k,v){
        if(k>0){
          ok=true;
          if(k>3){
            ok=false;
          }
          if(ok){
            exist_one=true;
            fdb+="<li>OK "+k+" - DAteMon "+v.date_mon+" - Distance: "+v.distance+" - Attiva "+v.attiva+"  - Totale "+v.totale+"</li>";
            sum_attiva+=v.attiva;
            sum_totale+=v.totale;
            if(v.attiva>0.1){
              sopra_soglia++;
            }
          }
          else{
            fdb+="<li>NO "+k+" - DAteMon "+v.date_mon+" - Distance: "+v.distance+" - Attiva "+v.attiva+"  - Totale "+v.totale+"</li>";
          }
        }
      });
      fdb+="</div>";

      var message='';
      var title='';
      var color='';

      var punture_perc=res.num_si/(res.num_si+res.num_no);
      var pppt=(100*punture_perc).toFixed(0)+"%";

      if((res.num_si+res.num_no)<50){
        fdb+="<div class='alert alert-warning'>Attenzione hai campionato poche olive ("+(res.num_si+res.num_no)+") consigliamo di prelevare almeno 100 olive.</div>";
      }

      //Non ci sono punti vicini
      if((res.num_si+res.num_no)===0){
        title="OCCORRE FARE IL CAMPIONAMENTO";
        message="Per avere un feedback devi prelevare le olive e contare il numero di olive con punture.";
      }
      else if((res.num_si+res.num_no)<50){
        title="OCCORRE AUMENTARE LE OLIVE CAMPIONATE";
        message="Per avere un feedback devi prelevare almeno 50 olive e contare il numero di olive con punture.";
      }
      else if(!exist_one){

        fdb+="<div class='alert alert-warning'>Attenzione non ci sono dati vicini ed aggiornati della rete di monitoraggio regionale. Il consiglio si basa solo sulla tua osservazione.</div>";

        if(punture_perc>0.1){
          title="RISCHIO";
          message="La percentuale di punture rilevata è elevata ("+pppt+"). Far controllare le olive da un tecnico con un campionamento che accerti il livello di infestazione attiva. ";
          color="rosso";
        }
        else{
          title="RISCHIO BASSO";
          message="La percentuale di punture rilevata è bassa ("+pppt+"), si consiglia di attendere una settimana e ripetere il prelievo.";
          color="verde";
        }
      }
      //infestazione assente per tutti i punti vicini
      else if (sum_totale===0){
        if(punture_perc>0.1){
          title="RISCHIO";
          message="Le punture rilevate sono elevate ("+pppt+").  Far controllare le olive da un tecnico con un campionamento che accerti il livello di infestazione attiva. ";
          color="rosso";
        }
        else{
          title="RISCHIO BASSO";
          message="Le punture rilevate sono basse ("+pppt+") e negli uliveti vicini non c'è infestazione. Si consiglia di attendere una settimana e ripetere il prelievo.";
          color="verde";
        }

      }
      else{
        var attiva_presunta=punture_perc; //*(sum_attiva/sum_totale);
        //NOTE: è troppo pericoloso calcolare l'attiva presunta in funzione della mortalità
        if(attiva_presunta<0.1 && sopra_soglia===0){
          if(attiva_presunta<0.03){
            title="RISCHIO ASSENTE";
          }
          else{
            title="RISCHIO BASSO";
          }
          message="La percentuale di punture rilevata è bassa ("+pppt+") ed in linea con quella gli oliveti monitorati nelle aree limitrofe. Si consiglia di attendere una settimana e ripetere il prelievo.";
          color="verde";

        }
        else if(attiva_presunta<0.1 && sopra_soglia>0){
          title="RISCHIO MEDIO";
          message="La percentuale di punture rilevata ("+pppt+") è sotto il livello di allerta. Attenzione, negli oliveti monitorati nelle aree limitrofe l'infestazione attiva sta crescendo. Ripetere il monitoraggio tra qualche giorno.";
          color="giallo";
        }
        else if(attiva_presunta>=0.1 && sopra_soglia===0){
          title="RISCHIO MEDIO ALTO";
          message="La percentuale di punture rilevata è elevata ("+pppt+"), ma gli oliveti delle aree limitrofe hanno infestazione attiva sotto la soglia consigliata. Per maggiore sicurezza far contrallore le olive da un tecnico con un campionamento che accerti il livello di infestazione attiva. ";
          color="giallo";
        }
        else if(attiva_presunta>=0.1 && sopra_soglia>0){
          title="RISCHIO ALTO";
          message="La percentuale di punture rilevata ("+pppt+") è in linea con quella degli oliveti monitorati nelle aree limitrofe che hanno superato la soglia di intervento consigliata. ";
          color="rosso";
        }
        else{
          title="RISCHIO SCONOSCIUTO";
          message="Non ci sono dati sufficienti per dare un consiglio.";
          color="rosso";
        }
      }

      fdb+="<div class='mosca-tile col-sm-12 col-xs-12'><div class='sfr-tile-int "+color+"'>";
      fdb+="<p class='text-center'>"+title+"</p>";
      fdb+="<p class='text-center'>"+message+"</p>";
      fdb+="</div></div>";

      jQuery('#feedback').html(fdb).show();
    },
    error: function(e){
      console.log(e);
    }
  });
}
