/*jshint esversion: 6 */
import global from './global.js';
import storage from './storage.js';
import t from './translate.js';

export function exe_routes(router){
  router.on({
    'tools/sync':function(){
      global.app.ptr.destroy('.ptr-content');

      synchronizes();
    }
  });

  return router;
}

//Hook obbligatorio per definire i tools del progetto
export function tools(){

  var defaultLanguage=global.language;

  var s=storage.get();
  if (s.language) {
    defaultLanguage=s.language;
  }

  var languages={
    'it':'Italiano',
    'en':'English'
  };

  var html='';
  html+="<div class='block-title central'>"+t('Impostazioni')+"</div>";

  html+='<div class="list links-list">';
    html+='<ul>';
      html+='<li>';
        html+='<a class="link external icon-only" href="#tools/sync" ><i class="f7-icons">cloud_download</i><span>'+t('synchronize_data')+'</span></a>';
      html+='</li>';
    html+='</ul>';
  html+='</div>';

  html+='<div class="list">';
    html+='<ul>';
      html+='<li>';
        html+='<a class="item-link smart-select smart-select-init" data-open-in="sheet">';
        html+='<select id="language_chose">';
          jQuery.each(languages,function(k,v){
            var selected='';
            if (k==defaultLanguage) {
              selected='selected';
            }
            html+='<option value="'+k+'" '+selected+'>'+v+'</option>';
          });
          html+='</select>';
          html+='<div class="item-content">';
            html+='<div class="item-inner">';
              html+='<div class="item-title">'+t('chose_language')+'</div>';
            html+='</div>';
          html+='</div>';
        html+='</a>';
      html+='</li>';
    html+='</ul>';
  html+='</div>';

  html+='<div class="list inline-labels">';
    html+='<ul id="appSettings"></ul>';
  html+='</div>';

  if (global.onlyCustomTools) {
    html='<div id="appSettings"></div>';
  }


  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  jQuery("#language_chose").change(function() {
    s.language=jQuery("#language_chose").val();
    storage.save(s);
    location.reload();
  });

  var button ='<button id="goToHome" class="button button-fill">'+t('back_home_page')+'</button>';

  jQuery('#footer').parent().show();
  jQuery('#footer').html(button);

  jQuery("#goToHome").click(function() {
    location.hash='';
  });
}

export function myProfile(){
  var s=storage.get();
  if(s.user) {

    var html='';

    html+='<form class="list" id="my-form">';
      html+='<ul>';
        html+='<li>';
          html+='<div class="item-content item-input">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Nome utente</div>';
              html+='<div class="item-input-wrap">';
                html+='<input type="text" name="name" value="'+s.user.username+'" disabled>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li>';
          html+='<div class="item-content item-input">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">E-mail</div>';
              html+='<div class="item-input-wrap">';
                html+='<input type="email" name="email" value="'+s.user.email+'" disabled>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li>';
          html+='<div class="item-content item-input">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">UID</div>';
              html+='<div class="item-input-wrap">';
                html+='<input type="email" name="email" value="'+s.user.uid+'" disabled>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
      html+='</ul>';
    html+='</form>';

    var content={'title': '', 'content': html, exclude_card:true};
    global.theme.render(content);

  }
  else {
    console.log(location.hash);
    location.hash = '#login';
  }
}

function synchronizes(){
  console.log("Sincronizzo...");

  var s=storage.get();
  // debugger
  s.lastUpdate=new Date().getTime();
  storage.save(s);

  if(global.project.synchronize){
    global.project.synchronize();
  }

  if(s.user) {
    global.app.dialog.preloader('Scarico i dati...');
    var urladdr = global.base_call+"synchronizes";
    jQuery.ajax({
      type: "POST",
      url: urladdr,
      data:{
        access_token:s.user.access_token
      },
      dataType: "json",
      success: function(data){
        console.log(data);
        global.app.dialog.close();

        var status='';
        var icon='up';
        if (data.ok==false){
          icon='down';
          status='NON';
        }

        global.app.notification.create({
          icon: '<i class="f7-icons">hand_thumbs'+icon+'_fill</i>',
          title: global.nomeApp,
          titleRightText: '',
          subtitle: status+' SINCRONIZZATO',
          text: 'Il tuo sistema '+status.toLowerCase()+' è sincronizzato.',
          closeOnClick: true,
          closeTimeout: 3000,
        }).open();

        var allOk=true;
        jQuery.each(data,function(k,v){
          allOk= allOk && v.ok;
        });

        if (allOk){
          var s=storage.get();
          if (typeof s.res=='undefined') {
            s.res={};
          }
          jQuery.each(data,function(k,v){
            s.res[k]=v;
            if (k=='farm' && v.rowCount==1) {
              s.id_farm=v.data[0].id_farm;
            }
          });

          s.lastUpdate=new Date().getTime();
          storage.save(s);
        }

        location.hash='';
      },
      error: function(e){
        console.log(e);
        global.app.dialog.close();
      },
    });

  }
  else {
    console.log(location.hash);
    location.hash = '#login';
  }
}

export function getGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4()+s4()+'-'+s4()+'-'+s4()+'-'+s4()+'-'+s4()+s4()+s4();
}

export function drawBanner(){
  var html='';
  html='<div class="block block-strong" style="margin-bottom:0px;"><p>Progetto finanziato dal PSR 2014-2020 della Regione Toscana</p></div>';

  if (global.nomeApp=='Osare') {
    html+='<img src="icons/bannerPSR_Osare.png" width=100%></img>';
  }
  else if (global.nomeApp=='AIDA') {
    html='<div class="block block-strong" style="margin-bottom:0px;"><p style="font-size:9px;">Progetto “AZIONI DI INFORMAZIONE E DIVULGAZIONE AGRICOLA A.I.D.A." – PSR 2014-2020 della Regione Toscana – Sottomisura 1.2 “Sostegno ad attività dimostrative ed azioni di informazione”. Fondo Europeo per l’Agricoltura e lo Sviluppo Rurale: L’Europa investe nelle zone rurali.</p></div>';
    html+='<img src="icons/bannerPSR_Aida.jpg" width=100%></img>';
  }
  else {
    html+='<img src="icons/bannerPSR.jpg" width=100%></img>';
  }

  return html;
}

export function register(){
  var s=storage.get();

  var aData = {};
  aData.id_device = s.id_device;
  aData.platform = s.platform;
  aData.model = s.model;
  aData.version_os = s.version_os;
  aData.app_version = s.version;
  aData.app_code = s.app_code;
  aData.notification_token = s.notification_token;

  if (s.user) {
    aData.access_token=s.user.access_token;
  }

  jQuery.ajax({
    type: "GET",
    url: global.base_call+'register',
    data:aData,
    dataType: "json",
    success: function(data){
      if (!data.ok) {
        console.log(data);
      }
    },
    timeout: 5000
  });

  // TODO fare API - non deve essere bloccante nel caso non ci sia la rete.
}
