/*jshint esversion: 6 */
// import settings from "../../../storage.js";


export default class Simple {


  constructor(id_survey_schema, section) {
    this.id_survey_schema=id_survey_schema;
    this.id_farm=id_farm;
    this.section=section;
  }

  //funzione di rendering
  render(current_survey){

    console.log("Sono in render!");
    var body="Funzione non specificata "+this.id_survey_schema+":  <input id='aaa' value='1' />";
    return body;

  }

  interact(){
    jQuery('#aaa').change(function(){
      console.log(jQuery('#aaa').val());
    });
  }

}
