/*jshint esversion: 6 */

import storage from "../../../storage.js";

import ae from "../../../global.js";

export default class Mosca {

  //Prima funzione
  constructor(id_farm, id_survey_schema, section) {
    this.id_survey_schema=id_survey_schema;
    this.id_farm=id_farm;
    this.section=section;
  }

  //funzione di rendering; riceve come input il survey corrente
  render(current_survey){
    var self=this;

    var body="";

    if (current_survey.step==1) {
      body+='<div class="block-title">Lavorazione del terreno</div>';
      body+='<div class="list">';

        body+='<ul>';

          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="lavorazione" value="non_lo_lavoro"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">Non lo lavoro</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';

          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="lavorazione" value="lavorazione_leggera"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">Lavorazione leggera</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';

        body+='</ul>';

      body+='</div>';


      body+='<div class="block-title">Gestione inerbimento</div>';
      body+='<div class="list">';
        body+='<ul>';
          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="inerbimento" value="inerbimento_naturale"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">Inerbimento naturale</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';

          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="inerbimento" value="cover-crops"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">Cover-crops</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';

          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="inerbimento" value="semine_miste"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">Semine miste</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';
        body+='</ul>';

      body+='</div>';

    }
    else if (current_survey.step==2) {

      body+='<div class="block-title">Gestione della difesa</div>';
      body+='<div class="list">';
        body+='<ul>';
          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="difesa" value="bio_int"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">Bio / int. volontario / int. obbligatorio</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';

          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="difesa" value="domande_sulle_pratiche"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">(fare domande sulle pratiche)</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';

          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="difesa" value="metti_trappole"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">Metti le trappole</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';
        body+='</ul>';

      body+='</div>';

      body+='<div class="block-title">Elementi naturali e semi-naturali</div>';
      body+='<div class="list">';
        body+='<ul>';
          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="elementi" value="hai_siepi"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">Hai siepi</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';

          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="elementi" value="aree_umide"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">Hai aree umide</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';

          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="elementi" value="zone_abbandonate"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">Zone abbandonate</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';
        body+='</ul>';

      body+='</div>';
    }
    else if (current_survey.step==3) {


      body+='<div class="block-title">Gestione idrica</div>';
      body+='<div class="list">';
        body+='<ul>';
          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="idrica" value="a"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">A</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';

          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="idrica" value="b"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">B</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';

          body+='<li>';
            body+='<label class="item-radio item-content">';
              body+='<input type="radio" name="idrica" value="c"/>';
              body+='<i class="icon icon-radio"></i>';
              body+='<div class="item-inner">';
                body+='<div class="item-title">C</div>';
              body+='</div>';
            body+='</label>';
          body+='</li>';
        body+='</ul>';

      body+='</div>';


    body+='<div class="block-title">Concimazione</div>';
    body+='<div class="list">';
      body+='<ul>';
        body+='<li>';
          body+='<label class="item-radio item-content">';
            body+='<input type="radio" name="concimazione" value="a"/>';
            body+='<i class="icon icon-radio"></i>';
            body+='<div class="item-inner">';
              body+='<div class="item-title">A</div>';
            body+='</div>';
          body+='</label>';
        body+='</li>';

        body+='<li>';
          body+='<label class="item-radio item-content">';
            body+='<input type="radio" name="concimazione" value="b"/>';
            body+='<i class="icon icon-radio"></i>';
            body+='<div class="item-inner">';
              body+='<div class="item-title">B</div>';
            body+='</div>';
          body+='</label>';
        body+='</li>';

        body+='<li>';
          body+='<label class="item-radio item-content">';
            body+='<input type="radio" name="concimazione" value="c"/>';
            body+='<i class="icon icon-radio"></i>';
            body+='<div class="item-inner">';
              body+='<div class="item-title">C</div>';
            body+='</div>';
          body+='</label>';
        body+='</li>';
      body+='</ul>';
    }

    return body;
  }

  interact(){

    var self=this;
    var s=storage.get();

    console.log(s);
    var current_survey=null;
    jQuery.each(s['survey_schema_'+self.id_farm][self.id_survey_schema],function(k,v){
      if (!v.closed) {
        current_survey=v;
      }
    });


    jQuery('input[type="radio"]').change(function() {
      var thisClicked=this;

      var s=storage.get();
      jQuery.each(s['survey_schema_'+self.id_farm][self.id_survey_schema],function(k,v){
        if (!v.closed) {
          v[jQuery(thisClicked).attr("name")]=jQuery(thisClicked)[0].value;
        }
      });
      storage.save(s);

      console.log(s);
    });

  }
}
