/*jshint esversion: 6 */

export function extendSelectNMWidgetF7(){

  var selectNMWidgetF7 = Dbmng.SelectNMWidget.extend({
    createWidget: function(){
      var self=this;

      self.aField.value = self.getFieldValue();

      var list='';
      if( typeof self.aField.voc_val[0] == 'object' ) {

        //cicla sugli elementi
        jQuery.each(self.aField.voc_val, function(k,v){
          jQuery.each(v, function(index, el) {

            var chkd="";
            jQuery.each(self.aField.value, function(_k, _v) {
              if(_v==index){
                chkd='checked="checked"';
              }
            });

            // list+="<div>"+JSON.stringify(self.aField.value)+"</div>";
            list+='<li>';
              list+='<label class="item-checkbox item-content">';
                list+='<input '+chkd+' class="real_widget" type="checkbox" name="demo-checkbox" id="'+index+'"/>';
                list+='<i class="icon icon-checkbox"></i>';
                list+='<div class="item-inner">';
                  list+='<div class="item-title">'+el+'</div>';
                list+='</div>';
              list+='</label>';
            list+='</li>';
          });
        });
      }

      var html='';
      html+='<div class="list">';
        html+='<ul>';
        html+=list;
        html+='</ul>';
      html+='</div>';
      return jQuery(html)[0];
    },
    getValue: function(){

      var active=[];
      var element=jQuery(this.widget).parent().parent().parent().find("input");

      jQuery.each(element,function(k,v){
        if (jQuery(v).is(":checked")==true) {
          active.push(jQuery(v).attr('id'));
        }
      });
      console.log("Diego",active);
      return (active);
    }
  });

  return selectNMWidgetF7;
}
