/*jshint esversion: 6 */
import global from '../../global.js';
import storage from '../../storage.js';
import * as tools from '../../tools.js';

import Simple from './custom/template_simple.js';
import Mosca from './custom/Mosca.js';
import { is_cordova } from '../../projects/agroambiente/commonJS.js';

export default class monitoring {


  //Che funzioni ci servono


  //1. sincronizza_dati
  //viene chiamata ad ogni passaggio di scheda
  //se ci sono dati non sincronizzati fa la chiamata e salva
  //viene chiamata in background


  //2. sinconizza schema
  //2.1 se lo schema non esiste su storage, la funziona effettivamente deve bloccare l'esecuzione e on success andare avanti
  //2.2 se invece lo schemagià esiste, restituisce lo schema e chiama una fuznione che, dietro le quinte, cerca di aggiornare lo schema


  getSurveySchema(id_farm){
    return new Promise(function (resolve, reject) {

      console.log(id_farm);
      var s=storage.get();
      if(s.user) {

        var toSync=[];

        jQuery.each(s['survey_schema_'+id_farm],function(k,v){
          jQuery.each(v,function(i,d){
            if (d.sync!=true && d.closed ) {
              toSync.push({
                guid:d.guid,
                field_guid:d.field,
                id_survey_schema:k,
                val:JSON.stringify(d),
                date: d.timestamp.substr(0,10)
              });
            }
          });
        });

        var fieldsToSync=[];
        jQuery.each(s['fields_'+id_farm],function(k,d){
          if (d.sync!=true) {
            fieldsToSync.push({
              guid:d.guid,
              lat:d.lat,
              lon:d.lon,
              name: d.name,
              id_farm_center:id_farm,
              id_crop: d.id_crop
            });
          }
        });

        console.log(fieldsToSync);

        var prenotazioniToSync=[];
        if (s.prenotazione_evento) {
          jQuery.each(s.prenotazione_evento,function(k,v){
            if (v.sync!=true) {
              prenotazioniToSync.push({
                partecipa:v.partecipa,
                timestamp:v.timestamp,
                id_event:v.id_event
              });
            }
          });
        }
        console.log(prenotazioniToSync);


        try {
          storeImages();
        }
        catch (e) {
          global.crash_log(e,'storeImages() -');
        }

        if (s.survey_schema) {
          var id_farm_remote=null;
          if (s.id_farm) {
            id_farm_remote=s.id_farm;
          }

          var arg={
            survey_schemas:s.survey_schema,
            id_farm:id_farm_remote
          };

          resolve(arg);
        }

        s=storage.get();
        var urladdr = global.base_path+'monitoring_webpack/'+"syncAepSchema";
        jQuery.ajax({
          type: "POST",
          url: urladdr,
          data:{
            access_token:s.user.access_token,
            toSync:toSync,
            prenotazioniToSync:prenotazioniToSync,
            fieldsToSync:fieldsToSync,
            toDelete:s.toDelete
          },
          dataType: "json",
          success: function(data){
            if (!data.ok) {
              if (typeof data.error_code!='undefined' && data.error_code=='not_valid_token') {
                delete s.user;
                storage.save(s);

                location.reload();
              }
            }
            else {
              var all_upload_OK=true;
              var all_upload_f_OK=true;
              var all_upload_p_OK=true;

              if (data.upload) {
                jQuery.each(data.upload,function(k,v){
                  if (v.ok!=true) {
                    all_upload_OK=false;
                  }
                });

                if (all_upload_OK) {
                  jQuery.each(s['survey_schema_'+id_farm],function(k,v){
                    jQuery.each(v,function(i,d){
                      if (d.sync!=true && d.closed) {
                        d.sync=true;
                      }
                    });
                  });
                }
              }

              if (data.field) {
                jQuery.each(data.field,function(k,v){
                  if (v.ok!=true) {
                    all_upload_f_OK=false;
                  }
                });

                if (all_upload_f_OK) {
                  jQuery.each(s['fields_'+id_farm],function(k,v){
                    if (v.sync!=true) {
                      v.sync=true;
                    }
                  });
                }
              }

              if (data.prenotazione) {
                jQuery.each(data.prenotazione,function(k,v){
                  if (v.ok!=true) {
                    all_upload_p_OK=false;
                  }
                });

                if (all_upload_p_OK) {
                  jQuery.each(s.prenotazione_evento,function(k,v){
                    if (v.sync!=true) {
                      v.sync=true;
                    }
                  });
                }
              }

              try {
                if (data.del) {
                  jQuery.each(data.del,function(k,v){
                    if (v.ok) {
                      if (typeof s.toDelete!='undefined') {
                        s.toDelete=removeA(s.toDelete,k);
                      }
                    }
                  });

                  storage.save(s);
                }
              }
              catch (e) {
                global.crash_log(e,'jQuery.each(data.del -');
              }

              try {
                s.id_farm=null;
                if (data.id_farm) {
                  if (typeof s.res=='undefined') {
                    s.res=[];
                  }

                  s.res.id_farm=data.id_farm.data;

                  if (s.res.id_farm.length==1) {
                    s.id_farm=s.res.id_farm[0].id_farm_center;
                    s.res.selected_id_farm=s.res.id_farm[0].id_farm_center;
                  }
                  else if(s.res.id_farm.length>1) {
                    console.log("redirect a tabella dove sceglie l'azienda");
                  }
                  else {
                    console.log("errore...");
                    alert("Nessun farm center collegato a questo utente");
                  }

                  storage.save(s);
                }
              }
              catch (e) {
                console.log(e);
                global.crash_log(e,'Nessun farm center collegato a questo utente -');
              }

              s=storage.get();
              // debugger
              console.log(storage.get());
              var toResolve=false;
              if (!s.survey_schema) {
                toResolve=true;
              }

              // Se non li metto fuori dall'if, non me li aggiorna
              s.survey_schema=data.data;
              storage.save(s);

              if (toResolve) {
                var arg_new={
                  survey_schemas:s.survey_schema,
                  id_farm:s.id_farm
                };

                resolve(arg_new);
              }

            }
          },
          error: function(e){
            console.log("error...");
            if (!s.survey_schema) {
              reject(e);
            }
          },
          //timeout: 10000 // sets timeout to 2 seconds Era stato messo il timeout perche la funzione era async
        });
      }
      else {
        console.log(location.hash);
        location.hash = '#login';
      }
    });
  }

  insertNewCampo(request){
    var coordinates=null;
    if (request.coordinates!=null) {
      coordinates={
        lat:request.coordinates.bufferedLatitude,
        lon:request.coordinates.bufferedLongitude
      };
    }

    if (coordinates==null) {
      coordinates={
        lat:0,
        lon:0
      };
    }

    var newCampoGuid=tools.getGuid();
    var newCampo={
      guid:newCampoGuid,
      sync:false,
      lat:coordinates.lat,
      lon:coordinates.lon,
      name:request.name,
      id_crop:request.id_crop
    };

    return newCampo;
  }

//getCampoVicino non va in data ma solo su storage
//non faccio una richiesta
//   getCampoVicino(id_farm, request){
//     return new Promise(function (resolve, reject) {

//       var coordinates=null;
//       if (request.coordinates) {
//         coordinates={
//           lat:request.coordinates.bufferedLatitude,
//           lon:request.coordinates.bufferedLongitude
//         };
//       }
//       var name=request.name;
//       var id_crop=request.id_crop;
//       var s=storage.get();
//       var campoVicino=null;
//       var distanzaMinima=null;

//       if (coordinates!=null) {
//         jQuery.each(s['fields_'+id_farm],function(k,v){

//           //Add check on crop
//           console.log(id_crop);
//           if (id_crop==v.id_crop) {
//             if (v.lat!=0 && v.lon!=0) {
//               var remoteCoordinates=[coordinates.lat,coordinates.lon];
//               var vCoordinates=[v.lat,v.lon];

//               var distance=getDistanceFromLatLonInKm(remoteCoordinates,vCoordinates);

//               if (distanzaMinima==null) {
//                 distanzaMinima=distance;
//                 campoVicino=v.guid;
//               }
//               else {
//                 // debugger
//                 if (distance<distanzaMinima) {
//                   distanzaMinima=distance;
//                   campoVicino=v.guid;
//                 }
//               }
//               // console.log("Distanza: "+distance+"km");
//               console.log("Distanza: "+(distance*1000)+" metri");
//             }
//           }
//           else {
//             console.log("Coltura diversa");
//           }
//         });
//       }

//       if (distanzaMinima==null || (distanzaMinima*1000)>700) {
//         campoVicino=null;
//       }

//       resolve(campoVicino);
//     });
//   }

}

function removeA(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

export function storeImages(base_path){
  if (typeof base_path=='undefined') {
    base_path=global.base_path+'monitoring_webpack/';
  }
  var settings=storage.get();
  // var images=settings.my_images;

  var all_images={};
  jQuery.each(settings.my_images,function(k,v){
    jQuery.each(v,function(i,d){
      all_images[i]=d;
      all_images[i].guid_survey=k;
    });
  });
  var images=all_images;

  var to_upload={};
  console.log("Ci sono "+Object.keys(images).length+" immagini<p/>");
  jQuery.each(images,function(k2,v2) {
    if(v2.saved==false || v2.updated==false){
      to_upload[k2]=v2;
    }
  });
  console.log("Ci sono "+Object.keys(to_upload).length+" immagini da caricare<p/>");

  console.log("TO UPLOAD");
  console.log(to_upload);

  var cont=0;
  jQuery.each(to_upload,function(k,v) {
    console.log(v);
    cont++;
    try{
      console.log("provo a caricare "+k);
      var call_type='insert';
      if(v.update){
        call_type='update';
      }

      var base64=v.base64;
      if (base64=='') {


        //debugger;
        getFileContentAsBase64(v.imageUrl,function(base64Image){
          var guid=k;
          console.log(v);
          base64=base64Image;
          console.log(v.imageUrl);
          console.log(base64);
          console.log(k);
          console.log(guid);
          var urladdr = base_path+"store_images/"+call_type+"/"+guid;
          jQuery.ajax({
            type: 'POST',
            // async:false,
            data:{
              guid_survey:v.guid_survey,
              note:v.commento,
              base64:base64,
              access_token:settings.user.access_token,
            },
            url: urladdr,
            dataType: "json",
            success: function(preverbale){
              console.log(" img "+guid+" "+preverbale.ok+"<p/>");
              updateImageStatus(guid, preverbale.ok);
            }
          });
        });
      }
      else {
        var guid=k;

        console.log("carico "+guid);
        var urladdr = base_path+"store_images/"+call_type+"/"+guid;
        jQuery.ajax({
          type: 'POST',
          // async:false,
          data:{
            guid_survey:v.guid_survey,
            note:v.commento,
            base64:base64,
            access_token:settings.user.access_token,
          },
          url: urladdr,
          dataType: "json",
          success: function(preverbale){
            if (!preverbale.ok) {
              alert(JSON.stringify(preverbale));
            }
            console.log(" img "+guid+" "+preverbale.ok+"<p/>");
            console.log(guid +' caricata '+preverbale.ok);
            updateImageStatus(guid, preverbale.ok);
          }
        });
      }
    }
    catch(e){
      console.log(e);
      console.log(" Errore con verbale "+k+" <p/>");
    }


  });
}

function updateImageStatus(guid, ok){
  var s=storage.get();

  jQuery.each(s.my_images,function(i,d) {
    jQuery.each(d,function(k,v){
      if(k==guid){
        console.log(v);
        v.saved=ok;
        v.updated=ok;
      }
    });
  });

  storage.save(s);
}


export function getFileContentAsBase64(path,callback){



  if(global.is_cordova()){
    window.resolveLocalFileSystemURL(path, gotFile, fail);
  }
  else{

    // debugger;
    window.webkitResolveLocalFileSystemURL(path, gotFile, fail);


    // var reader = new FileReader();
    // reader.onload = function(e) {

    //   debugger;
    // }
    // reader.readAsText(path);
  }

  function fail(e) {
    console.log("ERROR","Cannot found requested file");
  }

  function gotFile(fileEntry) {
    fileEntry.file(function(file) {
      var reader = new FileReader();
      reader.onloadend = function(e) {
        var content = this.result;
        callback(content);
      };
      // The most important point, use the readAsDatURL Method from the file plugin
      reader.readAsDataURL(file);
    });
  }
}
