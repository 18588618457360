/*jshint esversion: 6 */

import storage from "../../../storage.js";
import global from "../../../global.js";
import * as partecipa_storage from '../partecipa_storage.js';

import ae from "../../../global.js";
import t from "../../../translate.js";

export default class Mosca {

  //Prima funzione
  constructor(id_farm, id_survey_schema, section) {
    this.id_survey_schema=id_survey_schema;
    this.section=section;
    this.id_farm=id_farm;
  }

  //funzione di rendering; riceve come input il survey corrente
  render(current_survey){
    console.log(current_survey);

    var self=this;

    var body="";

    body+="<div>";
      body+="<div>";

        body+='<p class="row">';
          body+='<button puntura="no" class="col button button-fill button-large color-green btn-mosca mosca-no"><span>'+t("Puntura NO")+'</span></button>';
          body+='<button puntura="si" class="col button button-fill button-large color-red btn-mosca mosca-si"><span>'+t("Puntura SI")+'</span></button>';
        body+='</p>';

        var num_si=0;
        var num_no=0;
        var perc_infestazione=0;
        if(current_survey!=null){
          if(current_survey.num_si){
            num_si=current_survey.num_si;
          }
          if(current_survey.num_no){
            num_no=current_survey.num_no;
          }
          // if ((parseInt(current_survey.num_si)+parseInt(current_survey.num_no))!==0) {
          //   perc_infestazione=current_survey.num_si/(current_survey.num_si+current_survey.num_no);
          // }

        }

        var disabled='';
        if (global.nomeApp!=='Agro Abruzzo') {
          disabled=' disabled ';
        }

        body+='<div class="row">';
            body+='<div class="col-50 text-center">';
              body+='<input class="col center puntura_input" id="num_puntura_no" value="'+num_no+'" '+disabled+'></input>';
            body+='</div>';
            body+='<div class="col-50 text-center">';
              body+='<input class="col center puntura_input" id="num_puntura_si" value="'+num_si+'" '+disabled+'></input>';
              // body+='<h2 class="col center" id="num_puntura_si">'+num_si+'</h2>';
            body+='</div>';
        body+='</div>';

        var percentuale=self.calcolo_mosca(num_si,num_no);
        if (isNaN(percentuale)) {
          percentuale=0;
        }
        body+="<div class='col-xs-12'>";
          body+='<h2 class="text-center" id="calcolo_mosca">'+t("Infestazione al")+' <span id="percentuale_mosca">'+percentuale+'</span>%.</h2>';

          body+='<div id="motivation_div"></div>';
        body+="</div>";


      body+="</div>";
    body+="</div>";

    return body;
  }

  interact(survey){
    var self=this;
    var guid=survey.guid;

    jQuery(".btn-mosca").click(function() {
      var esito_puntura=jQuery(this).attr('puntura');

      var value=jQuery('#num_puntura_'+esito_puntura).val();
      value=parseInt(value)+1;
      jQuery('#num_puntura_'+esito_puntura).val(value);

      var num_si=parseInt(jQuery('#num_puntura_si').val());
      var num_no=parseInt(jQuery('#num_puntura_no').val());


      partecipa_storage.updatePartialSurvey(self.id_farm, self.id_survey_schema, guid, {
        num_no:num_no,
        num_si:num_si,
        perc_infestazione:self.calcolo_mosca(num_si,num_no)
      });

      // console.log(guid);
      // var s=storage.get();
      // jQuery.each(s['survey_schema_'+self.id_farm][self.id_survey_schema],function(k,v){
      //   if (!v.closed) {
      //     v.num_no=num_no;
      //     v.num_si=num_si;
      //
      //     v.perc_infestazione=self.calcolo_mosca(num_si,num_no);
      //   }
      // });
      // storage.save(s);
      // console.log(s);

      var percentuale=self.calcolo_mosca(num_si,num_no);
      jQuery('#percentuale_mosca').text(percentuale);

      var html='';
      html+='<h3 class="text-center motivation">'+num_si+' '+t("punture su")+' '+(num_no+num_si)+' olive osservate.</h3>';

      if ((num_si+num_no)==0) {
        html+='<h3 class="text-center motivation">Osserva la tua prima oliva..</h3>';
      }
      else if ((num_si+num_no)>0 && (num_si+num_no)<50) {
        html+='<h3 class="text-center motivation">Osserva almeno 50 olive!</h3>';
      }
      else if ((num_si+num_no)>=50 && (num_si+num_no)<70) {
        html+='<h3 class="text-center motivation">Forza dai! Ancora qualche oliva..</h3>';
      }
      else if ((num_si+num_no)>=70 && (num_si+num_no)<100) {
        html+='<h3 class="text-center motivation">A questo punto che fai? Non ci arrivi a 100 olive?!</h3>';
      }
      else if ((num_si+num_no)>=100) {
        html+='<h3 class="text-center motivation">Ottimo lavoro! Mica tutti arrivano a leggere questo messaggio! 🥳</h3>';
      }
      jQuery('#motivation_div').html(html);
    });

    jQuery(".puntura_input").change(function() {
      console.log("call");
      var num_si=parseInt(jQuery('#num_puntura_si').val());
      var num_no=parseInt(jQuery('#num_puntura_no').val());

      partecipa_storage.updatePartialSurvey(self.id_farm, self.id_survey_schema, guid, {
        num_no:num_no,
        num_si:num_si,
        perc_infestazione:self.calcolo_mosca(num_si,num_no)
      });

      // var s=storage.get();
      // jQuery.each(s['survey_schema_'+self.id_farm][self.id_survey_schema],function(k,v){
      //   if (!v.closed) {
      //     v.num_no=num_no;
      //     v.num_si=num_si;
      //
      //     v.perc_infestazione=self.calcolo_mosca(num_si,num_no);
      //   }
      // });
      // storage.save(s);

      var percentuale=self.calcolo_mosca(num_si,num_no);
      jQuery('#percentuale_mosca').text(percentuale);
    });

  }


  calcolo_mosca(si, no){
    var totale=si+no;
    if(totale>0){
      var percentuale=si/totale;
      return Math.round(percentuale*100);
    }
    else{
      return "-";
    }
  }
}
