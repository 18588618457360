/*jshint esversion: 6 */

// Qui ci sono le variabili globali

class global {
  constructor() {
    // INSERIRE LA BASE_CALL NEL RUN DEL PROGETTO
  }

  is_cordova() {
    return (typeof(cordova) !== 'undefined' || typeof(phonegap) !== 'undefined');
  }

  capitalizeFirstLetter(string) {
    string=string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  crash_log(e,where){
    console.log(e);

    if (global.crashlytics) {
      global.crashlytics.log(e.toString());
      global.crashlytics.logException(where+e.toString());
    }
  }
}

export default (new global());
