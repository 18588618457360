/*jshint esversion: 6 */

import storage from '../../../storage.js';
import global from '../../../global.js';
import notification from '../../../core/notification.js';
import t from '../../../translate.js';
import dbmng from '../../../ext/dbmng.js';
import * as partecipa from '../../../core/partecipa/partecipa_new.js';
import '../style.css';

import * as ecceolio from './ecceolio.js';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


global.base_path='https://ecceolio.aedit.it/api/';
global.base_call=global.base_path+'userplus/';
global.base_call_marketing=global.base_path+'marketing/';
global.nomeApp='EcceOlio';
global.download_app_path='https://ecceolio.aedit.it/app/EcceOlio.apk';
global.useSocialLogin=true;
global.settingsName='ecceolio_settings';

global.firebaseConfig = {
  apiKey: "AIzaSyCRkE1lO__50cHWW4YgNVRMDw1ess6FgaQ",
  authDomain: "ecceolio.firebaseapp.com",
  databaseURL: "https://ecceolio.firebaseio.com",
  projectId: "ecceolio",
  storageBucket: "ecceolio.appspot.com",
  messagingSenderId: "359840902294",
  appId: "1:359840902294:web:131a530abe7a073e7ca6b7",
  measurementId: "G-Q7ZPFDDY98"
};

global.firebase=firebase.initializeApp(global.firebaseConfig);

global.messaging = null;

if (firebase.messaging.isSupported()){
  global.messaging = firebase.messaging();
}

if (notification) {
  global.notification=notification;
}
notification.default_notify();

//Hook obbligatorio per definire la home progetto
export function home_page(){
  partecipa.home_page();
}

export function tools(){
  partecipa.partecipa_tools();
}

//Hook obbligatorio per definire le route del progetto
export function exe_routes(router){
  router.on({
    // 'choose_areas': function (param) {
    //   ecceolio.marketing_page();
    // },
    'notifica': function (param) {
      notification.notifica_home();
    }
  });

  router=partecipa.exe_routes(router);

  return router;
}

//Hook facoltativo per aggiungere delle sidebars al tema (non tutti i temi li gestiranno)
export function get_theme_param(theme_param){
  var sidebars=[
    {'label': 'Home page', 'icon_f7':'house', 'href':'#'},
    {'label': 'Notifica', 'icon_f7':'app_badge', 'href':'#notifica'},
  ];

  theme_param.sidebars=sidebars;
  theme_param.title=global.nomeApp;

  var s=storage.get();
  if (s.user) {
    theme_param.uid=s.user.uid;
    theme_param.username=s.user.username;
  }
  theme_param.ptr=false; // attiva/disattiva pull to refresh
  return theme_param;
}
