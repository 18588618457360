/*jshint esversion: 6 */

import global from '../../global.js';
import storage from '../../storage.js';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export function getAnnoCorrente(){
  var anno_corrente=new Date().getFullYear();
  return anno_corrente;
}

export function ymd2dmy(date){
  var aDate=date.split('-');
  var today_selector_date=aDate[2]+"/"+aDate[1]+"/"+aDate[0];
  return today_selector_date;
}

export function createMap(div,point,zoom){
  if (typeof zoom=='undefined') {
    zoom=10;
  }

  if (typeof point=='undefined') {
    point={latitude:42.5,longitude:14};
  }
  if(global.map){
    global.map.remove();
  }
  global.map = L.map(div).setView([point.latitude,point.longitude], zoom);
  var base=L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(global.map);

  var Esri_WorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
  });

  var baseMaps = {
    "Base": base,
    "Satellite": Esri_WorldImagery,
  };
  L.control.layers(baseMaps,{}).addTo(global.map);
}

export function is_cordova() {
  return (typeof(cordova) !== 'undefined' || typeof(phonegap) !== 'undefined');
}

export function dashIfIsNull(value){
  var returnValue=value;
  if (value==null) {
    returnValue='---';
  }
  else if (value=='') {
    returnValue='---';
  }

  if (typeof value!='string') {
    if (isNaN(value)) {
      returnValue='---';
    }
  }
  else {
    if (value=='NaN') {
      returnValue='---';
    }
  }

  return returnValue;
}

export function selectNeighborsStations(point,first){
  if (global.markers) {
    global.map.removeLayer(global.markers);
  }

  var request={
    lat:point.latitude,
    lon:point.longitude
  };

  if (is_cordova()) {

    var settings=storage.get();
    request.utente=settings.user.name;
    request.password=settings.user.password;
  }
  var urladdr = global.base_call_aedita+"monitoring_webpack/get_distance_from_point";
  jQuery.ajax({
  type: 'GET',
  data: request,
  url: urladdr,
  dataType: "json",
    success: function(near_points){
      global.markers = new L.FeatureGroup();

      jQuery.each(near_points.data, function(k, v){
        global.marker = L.marker([v.lat, v.lon]);
        global.markers.addLayer(global.marker);

        if (typeof my_position!='undefined') {
          bounds = L.latLngBounds([[v.lat, v.lon], [my_position.latitude, my_position.longitude]]);
          if (first) {
            global.map.fitBounds(bounds);
            global.map.zoomOut();
          }
        }

        if (k==0) {
          global.marker.bindPopup("Questo è il punto più vicino.").openPopup();
        }

        global.marker.on('click', function(){
          jQuery('#neighbors_stations').val(v.id_station);
        });
      });

      global.map.addLayer(global.markers);
    }
  });

  var req={
    lat:point.latitude,
    lon:point.longitude
  };
  if (is_cordova()) {
    var set=storage.get();
    req.utente=set.user.name;
    req.password=set.user.password;
  }
  var urladdre = global.base_call_aedita+"monitoring_webpack/get_neighbors_stations";
  jQuery.ajax({
  type: 'POST',
  url: urladdre,
  data:req,
  dataType: "json",
    success: function(stations){
      var opt_stations="";

      jQuery.each(stations.data,function(k,v) {
        if (v.disabled!=1) {
          opt_stations+="<option value='"+v.id_station+"'>"+v.name+" ("+parseFloat(v.distance).toFixed(1)+" Km)</option>";
        }
      });
      jQuery('#neighbors_stations').html(opt_stations);

      if (typeof lastStation!='undefined') {
        jQuery('#neighbors_stations').val(lastStation);
        lastStation=undefined;
      }

      jQuery('#neighbors_stations').change(function(){
        global.map.closePopup();
      });
    }
  });
}

export function addZero(i) {
  if (i < 10) {
      i = "0" + i;
  }
  return i;
}

// export function getStaticData(toStore){
//   var objToStore={};
//   if (typeof toStore!='undefined') {
//     objToStore=toStore;
//   }
//
//   // var settings=jQuery.jStorage.get('aeditapp_settings');
//
//   var s=storage.get();
//   var request={
//     access_token:s.user.access_token
//   };
//
//   console.log("22222");
//   var urladdr = global.base_call_aedita+"monitoring_webpack/getAllAppData";
//   jQuery.ajax({
//     type: "POST",
//     url: urladdr,
//     async:false,
//     data:request,
//     dataType: "json",
//     success: function(data){
//
//       var s=storage.get();
//       // console.log("JJBB",s);
//
//       var ok=true;
//       jQuery.each(data,function(k,v){
//         if (!v.ok) {
//           ok=false;
//         }
//       });
//       if (!ok) {
//         alert("C'è stato un errore nel caricamento dei dati statici.");
//       }
//       var object = jQuery.extend({}, s, data);
//
//
//       console.log("SAVING....");
//       console.log(object);
//       storage.save(object);
//
//       // debugger
//       // console.log(storage.get());
//       // ???
//       // jQuery.jStorage.set('aeditapp_tostore',objToStore);
//     },
//     error: function(e){
//       console.log(e);
//     },
//   });
// }

export function isUpperCase(str){
  return str === str.toUpperCase();
}

export function isLowerCase(str){
  return str === str.toLowerCase();
}


export function getTodayDate(format){
  var today=new Date();

  var today_yymmdd=new Date(today).getFullYear()+"-"+addZero(new Date(today).getMonth()+1)+"-"+addZero(new Date(today).getDate());
  today=addZero(new Date(today).getDate())+"/"+addZero(new Date(today).getMonth()+1)+"/"+addZero(new Date(today).getFullYear());
  if (typeof format!='undefined') {
    if (format=='dd/mm/yyyy') {
      return today;
    }
    else if (format=='yyyy-mm-dd') {
      return today_yymmdd;
    }
    else {
      alert('Formato non ancora sviluppato.');
      return today_yymmdd;
    }
  }
  else {
    return today_yymmdd;
  }
}

export function loading(margin){
  margin=margin-48*2;//tolgo il doppio del font size, che è di 3em che corrisponde a 48px
  var loading='<div class="container" style="margin-left:'+margin+'px;"><h1><i class="fa fa-spinner fa-pulse fa-3x fa-fw loading-color"></i><span class="sr-only"></span></h1></div>';
  return loading;
}

export function returnItMonth(month){
  if (month==1) {
    return 'Gennaio';
  }
  else if (month==2) {
    return 'Febbraio';
  }
  else if (month==3) {
    return 'Marzo';
  }
  else if (month==4) {
    return 'Aprile';
  }
  else if (month==5) {
    return 'Maggio';
  }
  else if (month==6) {
    return 'Giugno';
  }
  else if (month==7) {
    return 'Luglio';
  }
  else if (month==8) {
    return 'Agosto';
  }
  else if (month==9) {
    return 'Settembre';
  }
  else if (month==10) {
    return 'Ottobre';
  }
  else if (month==11) {
    return 'Novembre';
  }
  else if (month==12) {
    return 'Dicembre';
  }
}

export function getGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function doLogin(){
  var html='';
  html+='<div class="coverpage">';
    html+='<div class="loginbox-wrapper">';
      html+='<div class="loginbox">';
        html+='<h2 class="center-text">Welcome</h2>';
        html+='<h4 class="center-text">Enter your credentials bellow</h4>';
        html+='<input class="loginbox-username" placeholder="Username" type="text" id="username">';
        html+='<input class="loginbox-password" placeholder="Password" type="password" id="user_password">';
        html+='<a href="#" class="button button-green" id="submitLogin">Login</a>';
      html+='</div>';
    html+='</div>';
  html+='</div>';

  jQuery('#content').html(html);

  var s=storage.get();
  jQuery("#submitLogin").click(function() {
    var urladdr = global.base_call_aedita+"monitoring_webpack/token";
    jQuery.ajax({
      type: "POST",
      url: urladdr,
      data:{
        grant_type:'password',
        username:jQuery('#username').val(),
        password:jQuery('#user_password').val(),
        client_id:'smb-portal'
      },
      dataType: "json",
      success: function(data){
        s.user.access_token=data.access_token;
        settings.save(s);
      },
      error: function(e){
        console.log(e);
      },
    });

  });
}

export function requireMail(){
  var html='';
  html+='<div class="coverpage">';
    html+='<div class="loginbox-wrapper">';
      html+='<div class="loginbox emailbox">';
        html+='<h2 class="center-text">Welcome</h2>';
        html+='<h4 class="center-text">Enter your e-mail bellow</h4>';
        html+='<input class="loginbox-username" placeholder="yourmail@domain.com" type="mail" id="email">';
        html+='<a href="#" class="button button-green" id="sendMail">Invia mail</a>';
      html+='</div>';
    html+='</div>';
  html+='</div>';

  jQuery('#content').html(html);
}

export function errorPage(){
  var html='';
  html+='<div class="coverpage coverpage-bg3 coverpage-error">';
    html+='<div class="coverpage-content">';
      html+='<h1>404</h1>';
      html+="<h4>You're on the wrong page!</h4>";
      html+="<p>We're either building this page now, and you're here too soon, or the page doesn't exist! So how about going back home?";
      html+='</p>';
      html+='<a href="#" class="uppercase center-button button button-red">Homepage</a>';
      html+='</div>';
    html+='<div class="overlay"></div>';
  html+='</div>';

  jQuery('#content').html(html);
}

export function soonPage(){
  var html='';
  html+='<div class="coverpage coverpage-bg3 coverpage-construction">';
    html+='<div class="coverpage-content">';
      html+='<h1>Coming Soon</h1>';
      html+="<h4>We're still building this page</h4>";
      html+='<p>';
        html+="This page is curently under construction, but feel";
        html+="free to check it later we'll have it complete realy soon!";
      html+='</p>';
      html+='<div class="countdown">';
      html+='</div>';
      html+='<a class="uppercase center-button button button-red" href="#">Homepage</a>';
    html+='</div>';
    html+='<div class="overlay"></div>';
  html+='</div>';

  jQuery('#content').html(html);
}

export function getDistanceFromLatLonInKm([lat1,lon1],[lat2,lon2]) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1);
  var a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180);
}
