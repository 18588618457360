/*jshint esversion: 6 */

import global from "../global.js";
import storage from "../storage.js";
import * as tools from "../tools.js";
import t from '../translate.js';
import * as p_data from './partecipa/data.js';

import writeImage from './write_image.js';

class photo {

  getPhotoDiv(guid_rilievo){
    var html='';
    var fileda4=1;

    var s=storage.get();
    var num_photo=0;

    try {
      if (typeof s.my_images=='undefined') {
        s.my_images={};
      }
      if (typeof s.my_images[guid_rilievo]=='undefined') {
        s.my_images[guid_rilievo]={};
      }

      num_photo=Object.keys(s.my_images[guid_rilievo]).length;
    }
    catch (e) {
      console.log(e);
    }

    fileda4=parseInt(parseInt(num_photo/4)+1);
    console.log(fileda4);
    for (var i = 0; i < fileda4; i++) {
      html+='<div class="row" style="margin-bottom:10px;">';

        html+='<div class="photo" id="photo_'+(1+(i*4))+'">';
          html+='<div class="take_photo">';
            html+='<i style="top: 33%;" class="icon f7-icons">photo</i>';
          html+='</div>';
        html+='</div>';

        html+='<div class="photo" id="photo_'+(2+(i*4))+'">';
          html+='<div class="take_photo">';
            html+='<i style="top: 33%;" class="icon f7-icons">photo</i>';
          html+='</div>';
        html+='</div>';

        html+='<div class="photo" id="photo_'+(3+(i*4))+'">';
          html+='<div class="take_photo">';
            html+='<i style="top: 33%;" class="icon f7-icons">photo</i>';
          html+='</div>';
        html+='</div>';

        html+='<div class="photo" id="photo_'+(4+(i*4))+'">';
          html+='<div class="take_photo">';
            html+='<i style="top: 33%;" class="icon f7-icons">photo</i>';
          html+='</div>';
        html+='</div>';

      html+='</div>';
    }

    return html;
  }

  fillPhotoDiv(guid_rilievo){
    var s=storage.get();
    var cont=0;

    if (typeof s.my_images=='undefined') {
      s.my_images={};
    }
    if (typeof s.my_images[guid_rilievo]=='undefined') {
      s.my_images[guid_rilievo]={};
    }

    jQuery.each(s.my_images[guid_rilievo],function(k,v){
      // var time=500;
      // setTimeout( function(){

        var path=v.base64;
        if (path=="") {
          path=v.imageUrl;
        }

        p_data.getFileContentAsBase64(v.imageUrl,function(base64Image){
          cont++;
          var base64=base64Image;
          // photos.push(base64);
          // console.log(cont,base64);
          var html='';
          html+='<div>';
            html+='<img width="60" height="80" src="'+base64+'"></img>';
          html+='</div>';
          jQuery('#photo_'+(cont)).html(html);
        });
      // }, time);
      // time += 500;
    });

    var photos=[];
    jQuery.each(s.my_images[guid_rilievo],function(k,v){
      // if (!global.is_cordova()) {
      //   photos.push(v.base64);
      // }
      // else {
      // }

      p_data.getFileContentAsBase64(v.imageUrl,function(base64Image){
        var base64=base64Image;
        photos.push(base64);
      });

    });

    setTimeout(function(){
      // console.log(photos);
      var myPhotoBrowserStandalone = global.app.photoBrowser.create({
        photos: photos
      });
      jQuery(".photo").click(function() {
        if (photos.length>0) {
          myPhotoBrowserStandalone.open();
        }
      });
    },1500);


  }

  takePicture(guid_rilievo){
    var self=this;
    if(global.is_cordova()){

      var cameraOptions={
        quality: 75,
        correctOrientation:true,
        destinationType: navigator.camera.DestinationType.FILE_URI,
        targetWidth:1280,
        targetHeight:1280
      };

      navigator.camera.getPicture(
        function(imageUrl){
          var base64 = "";

          self.save_image(base64,imageUrl,guid_rilievo);
          console.log(imageUrl);
        },
        function(message){
          alert(message);
        },
      cameraOptions);
    }
    else{
      if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
        alert('The File APIs are not fully supported in this browser.');
      }
      var input = document.getElementById('scatta_foto');
      // console.log(input);
      if (!input) {
         alert("Um, couldn't find the file input element.");
      }
      else if (!input.files) {
        alert("This browser doesn't seem to support the `files` property of file inputs.");
      }
      else if (!input.files[0]) {
        alert("Please select a file");
      }
      else {
        console.log("FOTOFINA");



        var file = input.files[0];
        var fr = new FileReader();
        fr.onload = function(){
          var base64=fr.result;


          // filesystem:http://localhost:8080/persistent/IMG_20210401_113317.jpg
          // filesystem:http://localhost:8080/persistent/IMG_20210401_113317.jpg

          writeImage(file.name, file,1);

          self.save_image('','filesystem:'+window.location.origin+'/persistent/'+file.name,guid_rilievo);
          // console.log('filesystem:http://localhost:8080/persistent/'+file.name);
          // console.log(fr);
        };
        fr.readAsDataURL(file);
     }
    }
  }

  save_image(base64,imageUrl,guid_rilievo){
    var self=this;
    console.log("Base64",base64);
    console.log("imageurl",imageUrl);

    var s=storage.get();
    var my_images=s.my_images;

    if (typeof s.my_images=='undefined') {
      s.my_images={};
    }
    if (typeof s.my_images[guid_rilievo]=='undefined') {
      s.my_images[guid_rilievo]={};
    }

    if (my_images==null) {
      my_images={};
    }

    if (my_images[guid_rilievo]==null) {
      my_images[guid_rilievo]={};
    }

    var guid=tools.getGuid();
    my_images[guid_rilievo][guid]={'base64':base64,'imageUrl':imageUrl,'saved':false};
    s.my_images=my_images;
    console.log(s);
    storage.save(s);

    self.fillPhotoDiv(guid_rilievo);
    // location.reload();
  }

}

export default (new photo());
