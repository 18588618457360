/*jshint esversion: 6 */

import global from "../global.js";
import storage from "../storage.js";
import t from '../translate.js';
import photo from "./photo.js";
import * as partecipa_data from  "./partecipa/data.js";

class Marketing {
  datiAziendali(){
    var html='';
    html+="<div id='dbmng'></div>";
    var content={'title': '', 'content': html, exclude_card:true};
    global.theme.render(content);

    jQuery('#footer.toolbar-inner').css('padding','0');

    var buttons='';
    buttons+='<button id="cancel" style="margin:5px;" class="col button button-large button-raised button-fill color-red">'+t("Annulla")+'</button>';
    buttons+='<button id="save" style="margin:5px;" class="col button button-large button-raised button-fill color-green">'+t("Salva")+'</button>';
    jQuery('#footer').html(buttons);

    console.log("modifica dei dati aziendali");

    var s=storage.get();
    try {
      var id_farm=s.id_farm;

      if (s.res.farm) {
        id_farm=s.res.farm.data[0].id_farm;
      }
      else {
        id_farm=s.res.selected_id_farm;
      }
    }
    catch (e) {
      console.log(e);
    }
    var oForm;

    var urladdr = global.base_call_marketing+"get_marketing";
    jQuery.ajax({
      type: "POST",
      url: urladdr,
      data:{
        id_farm:id_farm
      },
      dataType: "json",
      success: function(aForm){



        var selectNMWidgetF7 = Dbmng.SelectNMWidget.extend({
          createWidget: function(){
            var self=this;

            var list='';
            if( typeof self.aField.voc_val[0] == 'object' ) {
              jQuery.each(self.aField.voc_val, function(k,v){
                jQuery.each(v, function(index, el) {
                  list+='<li>';
                    list+='<label class="item-checkbox item-content">';
                      list+='<input class="real_widget" type="checkbox" name="demo-checkbox" id="'+index+'"/>';
                      list+='<i class="icon icon-checkbox"></i>';
                      list+='<div class="item-inner">';
                        list+='<div class="item-title">'+el+'</div>';
                      list+='</div>';
                    list+='</label>';
                  list+='</li>';
                });
              });
            }

            var html='';
            html+='<div id="aree_checkbox">';
              html+='<ul>';
              html+=list;
              html+='</ul>';
            html+='</div>';
            return jQuery(html)[0];
          },
          getValue: function(){
            var active=[];
            jQuery.each(jQuery('#aree_checkbox input'),function(k,v){
              if (jQuery(v).is(":checked")==true) {
                active.push(jQuery(v).attr('id'));
              }
            });

            return JSON.stringify(active);
          }
        });


        var farmData=aForm.farmData.data[0];
        var theme_f7=new Dbmng.Framework7Theme();

        // console.log(JSON.parse(aForm.aForm));
        aForm=JSON.parse(aForm.aForm);
        jQuery.each(aForm.fields,function(k,v){
          if (v.widget=='select_nm') {
            console.log("SE NM");
            v.external_widget=selectNMWidgetF7;
          }
        });

        console.log(aForm);


        oForm = new Dbmng.Form ({'aForm':aForm, theme:theme_f7});

        jQuery('#dbmng').append(oForm.createForm(farmData));
        jQuery('#dbmng').find('.label-form').addClass('field_separator');

        try {
          // Brutto, ma per ora l'unico modo....
          jQuery('input[type=hidden]').parents('li').css('display','none');
        }
        catch (e) {
          console.log(e);
        }


        if (global.nomeApp=='AIDA') {
          if (typeof farmData!=='undefined') {
            try {
              jQuery.each(JSON.parse(farmData.aree),function(k,v){
                jQuery("#"+v).attr("checked", true);
              });
            }
            catch (e) {
              console.log(e);
            }
          }
        }

        // console.log(global.nomeApp);
        // debugger
        if (global.nomeApp=='SheepAllChain') {
          var ph='';
          ph+='<div class="card">';
            ph+="<div id='photo' class='card-content card-content-padding'>";
              ph+='<p class="row">';
                if (!global.is_cordova()) {
                  ph+='<input id="scatta_foto" class="scatta_foto col" type="file" accept="image/*"></input>';
                }
                else {
                  ph+='<button id="scatta_foto" class="scatta_foto col button button-large button-raised" type="file" accept="image/*"><span>Aggiungi</span> <i class="icon f7-icons">camera_fill</i></button>';
                }
              ph+='</p>';

              try {
                ph+=photo.getPhotoDiv(s.id_farm);
              }
              catch (e) {
                global.crash_log(e,"photo.getPhotoDiv(s.id_farm) -");
              }
            ph+="</div>";
          ph+="</div>";
          jQuery('#dbmng').append(ph);

          photo.fillPhotoDiv(s.id_farm);

          if (global.is_cordova()) {
            jQuery("#scatta_foto").click(function() {
              photo.takePicture(s.id_farm);
            });
          }
          else {
            jQuery("#scatta_foto").change(function() {
              photo.takePicture(s.id_farm);
            });
          }


        }

        jQuery("#cancel").click(function() {
          location.hash='';
        });

        jQuery("#save").click(function() {

          if (jQuery('#photo').length>0) {
            partecipa_data.storeImages(global.base_call_marketing);
          }

          var request=oForm.getValue();
          console.log(request);
          if (typeof farmData=='undefined') {
            var s=storage.get();
            if (s.res.farm) {
              request.id_farm=s.res.farm.data[0].id_farm;
            }
            else {
              request.id_farm=s.res.selected_id_farm;
            }
          }
          global.app.dialog.preloader();
          var urladdr = global.base_call_marketing+"save_marketing";
          jQuery.ajax({
           type: "POST",
           url: urladdr,
           data:request,
           dataType: "json",
           success: function(data){
             global.app.dialog.close();

             console.log(data);
             if (data.ok) {
               global.app.dialog.create({
                 text: '<img src="icons/correct.gif?'+Math.random()+'" height="200"></img>',
               }).open();

               setTimeout(function(){

                 global.app.dialog.close();
                 location.hash='#tools';
               },2000);
             }
             else {
               global.app.dialog.create({
                 title:"C'è un errore nel salvataggio",
                 text: '<img src="icons/wrong.gif?'+Math.random()+'" height="200"></img>',
               }).open();

               setTimeout(function(){
                 global.app.dialog.close();
                 location.hash='';
               },2000);
             }
           },
           error: function(e){
             global.app.dialog.close();
             global.app.dialog.create({
               title:"Purtroppo c'è un errore nel salvataggio!",
               text: '<img src="icons/wrong.gif?'+Math.random()+'" height="200"></img>',
             }).open();
             setTimeout(function(){
               global.app.dialog.close();
               location.hash='';
             },2000);
             console.log(e);
           },
          });
        });
      }
    });
  }
}

export default (new Marketing());
