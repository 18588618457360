/*jshint esversion: 6 */

import global from "../global.js";

class GPS {

  /*
  opt={
      //funzione lanciata quando si ha la coordinata definitiva
      position: function(pos){},
      //Funzione lanciata per la coordinata temporanea (se fa il buffer)
      position_temp: function(pos){},
      buffer_max_size: 2
    }
  }
  */
  startGPS(opt){
    // debugger
    var self=this;
    self.opt=opt;

    // jQuery('#manual-Map').addClass('disabled');
    // jQuery('#GPS-Map').addClass('disabled');

    console.log("GPS ACTIVE: "+global.gps_active);
    if (global.gps_active==true) {
      self.stopGPS();
    }

    // console.log("START GPS");
    // if (typeof global.interval_gps!=='undefined') {
    //   self.stopGPS();
    // }

    global.gps_active=true;

    self.numRilieviGPS=0;

    var use_advanced=false;

    // console.log("OPT:");
    // console.log(self.opt);

    if(global.is_cordova() && (typeof self.opt.getFasterPosition=='undefined' || self.opt.getFasterPosition==false) ){
      if (typeof cordova.plugins!='undefined') {
        console.log(cordova.plugins);
        cordova.plugins.diagnostic.isGpsLocationEnabled(
          function(enabled){
            console.log("GPS location is " + (enabled ? "enabled" : "disabled"));
            if (!enabled) {
              if(window.confirm("GPS non attivo, per prendere le coordinate è necessario, attivarlo?")){
                cordova.plugins.diagnostic.switchToLocationSettings();
              }
            }
          },
          function(error){
            console.error("The following error occurred: "+error);
          }
        );
      }
      else {
        console.warn("Plugin non disponibili.");
      }

      var GpsOpt={
        "minTime":1000,         // Min time interval between updates (ms)
        "minDistance":2,       // Min distance between updates (meters)
        "noWarn":true,         // Native location provider warnings
        "providers":"gps",     // Return GPS, NETWORK and CELL locations
        "useCache":false,       // Return GPS and NETWORK cached locations
        "satelliteData":false, // Return of GPS satellite info
        "buffer":true,        // Buffer location data
        "bufferSize":10,         // Max elements in buffer
        "bufferedLatitude": 0,
        "bufferedLongitude":0,
        "bufferedAccuracy":48,
        "signalStrength":false // Return cell signal strength data
      };
      AdvancedGeolocation.start(function(success_position){
        console.log("SUCCESS POSITION",success_position);
        try{
          var jsonObject = JSON.parse(success_position);
          console.log(jsonObject);

          if (jsonObject.provider=='gps') {
            var position=jsonObject;
            self.positioning(position);
          }
          else{
            //TODO attualmente non entra perche accetta solo GPS
          }
        }
        catch(exc){
          console.log("Invalid JSON: " + exc);
        }
      },
      function(error){
        console.log("ERROR! " + JSON.stringify(error));
        if(self.opt.error){
          self.opt.error(error);
        }
      },
      GpsOpt);

      // console.log("sono nell'if");
    }
    else{


      //2 minutes of cache...
      var geolocation_opt={
        "timeout":5000,
        "maximumAge": 2*60*1000
      };

      navigator.geolocation.getCurrentPosition(function(position){
        // console.log(position);
        var position_ok=position.coords;
        position_ok.provider='html5';


        var add_random=true;

        // console.log("add_random: "+add_random);

        if(add_random){
          var spost=0.001*(Math.random()-0.5);
          var spost2=0.001*(Math.random()-0.5);
          position_ok.bufferedLatitude=position_ok.latitude+spost2;
          position_ok.bufferedLongitude=position_ok.longitude+spost2;
          position_ok.bufferedAccuracy=position_ok.accuracy*Math.random();
        }
        else{
          position_ok.bufferedLatitude=position_ok.latitude;
          position_ok.bufferedLongitude=position_ok.longitude;
          position_ok.bufferedAccuracy=position_ok.accuracy;
        }
        self.opt.position(position_ok);
        // self.positioning(position_ok);
      },
      function(error){
        console.log("ERRORGPS! " + JSON.stringify(error));
        if(self.opt.error){
          self.opt.error(error);
        }
      }, geolocation_opt);
    }
  }

  stopGPS(){
    global.gps_active=false;

    console.log("STOP GPS");
    //TODO capire bene come viene usato questo
    global.coordinates=null;

    if(global.is_cordova()){
      AdvancedGeolocation.stop();
      // window.clearInterval(global.interval_gps);
    }
    // else{
    //   window.clearInterval(global.interval_gps);
    // }
  }

  //la funzione veine chiamata ad ogni nuova coordinata presa dal GPS in modalità buffering
  positioning(position){

    // console.log("position kl:",position);

    var self=this;

    self.numRilieviGPS++;

    var buffer_max_size=2;
    if(self.opt.buffer_max_size){
      buffer_max_size=self.opt.buffer_max_size;
    }
    // debugger
    if(self.numRilieviGPS>buffer_max_size){
      self.stopGPS();
      if(self.opt.position){
        self.opt.position(position);
      }
      else{
        console.log("CREATE position callback");
      }
    }
    else{
      if(self.opt.position_temp){
        self.opt.position_temp(position);
      }
      else{
        console.log("CREATE position_temp callback");
      }
    }

    if (self.opt.save_place) {
      if(!global.coordinates){
        global.coordinates=[];
      }
      // console.log(global);
      // debugger
      try{
        var request={
          lat:global.coordinates.bufferedLatitude,
          lon:global.coordinates.bufferedLongitude
        };
        self.reverseGeocode(request);
      }
      catch(e){
        console.log(e);
      }
    }

  }

  reverseGeocode(request){
    console.log("CALL GEOREVERSE");
    var urladdr = 'http://nominatim.openstreetmap.org/reverse?format=json&lat='+request.lat+'&lon='+request.lon+'&zoom=20&addressdetails=1&accept-language=it-it';
    jQuery.ajax({
      type: 'POST',
      async:false,
      url: urladdr,
      dataType: "jsonp",
      jsonp:'json_callback',
      success: function(osm){
        var place='';
        if(typeof osm.address.city != 'undefined'){
          place=osm.address.city;
        }
        else if(typeof osm.address.town != 'undefined'){
          place=osm.address.town;
        }
        else if(typeof osm.address.village != 'undefined'){
          place=osm.address.village;
        }
        else if(typeof osm.address.hamlet != 'undefined'){
          place=osm.address.hamlet;
        }

        if(typeof osm.address.suburb != 'undefined'){
          place+=" - "+osm.address.suburb;
        }
        else if(typeof osm.address.road != 'undefined' ){
          if(osm.address.road.length>10)
          place+=" - "+osm.address.road;
        }

        global.app.dialog.close();
        if (!global.coordinates) {
          global.coordinates={};
        }
        global.coordinates.place=place;
        // console.log("place: "+place);

      },
      'error': function(err){
        global.app.dialog.close();
        // alert("err "+err);
      }
    });

  }

  manualMap(callback){
    global.map.dragging.enable();

    function map_event_move(){
      console.log(global.marker);
      if(global.marker){
        global.map.removeLayer(global.marker);
      }
      global.marker=L.marker(global.map.getCenter()).addTo(global.map);
    }

    function map_event_moveend(){
      console.log("MOVE END");
    }

    function map_event_dragend(){
      console.log("DRAG END");
      if(callback){
        var coord={
          "lat":global.marker._latlng.lat,
          "lon":global.marker._latlng.lng
        };
        callback(coord);
      }
      else{
        jQuery('#manual_gps_latitude').val(global.marker._latlng.lat);
        jQuery('#manual_gps_longitude').val(global.marker._latlng.lng);

        jQuery('#manual_gps_latitude, #manual_gps_longitude').change();
      }
    }

    global.map.on("move"   , map_event_move );
    global.map.on("moveend", map_event_moveend);
    global.map.on("dragend", map_event_dragend);
  }
}

export default (new GPS());
